.planner-screen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  background-image: linear-gradient(#e0e0e0 1px, transparent 1px),
    linear-gradient(to right, #e0e0e0 1px, transparent 1px);
  background-size: 70px 70px;
}

.arrow-container {
  position: absolute;
  top: 5px;
  right: 20px;
  text-align: center;
}

.curved-arrow {
  width: 90px;
  height: 30px;
  display: block;
  margin: 0 auto;
}

.clickable-text {
  color: #00796b;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  margin-top: 8px;
}

.clickable-text:hover {
  color: #004d40;
  text-decoration: underline;
}

